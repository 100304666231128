
<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Skenerji za inventuro">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.modal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        Dodaj skener
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="stockTakingScanners" v-slot="props">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-1" @click="$refs.modal.open(stockTakingScanners[props.row.originalIndex])"><feather-icon icon="EditIcon"/></b-button>
                    <b-button variant="danger" class="" @click="remove(stockTakingScanners[props.row.originalIndex].id)"><feather-icon icon="Trash2Icon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 3">
                    <b-badge v-if="props.formattedRow.registered" variant="success">DA</b-badge>
                    <b-badge v-else variant="danger">
                        NE - {{ props.row.registration_code.toUpperCase() }}
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 1">
                    {{ getClientNameFromId(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>
        <AddStockTakingScanner ref="modal" :clients="clients" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddStockTakingScanner from '@/views/StockTakingScanners/AddStockTakingScanner.vue'
    import {BButton, BCard, BOverlay, BCol, BRow, BBadge} from 'bootstrap-vue'

    export default {
        components: {BasicTable, AddStockTakingScanner, BCard, BOverlay, BButton, BCol, BRow, BBadge},
        data() {
            return {
                clients: [],
                stockTakingScanners: [],
                columns: [
                    {
                        label: 'Stranka',
                        field: 'client_id',
                        displayType: 1,
                        filterOptions: {
                            enabled: true,
                            filterFn: this.filterClientNames
                        }
                    },
                    {
                        label: 'Ime naprave',
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Status',
                        field: 'registered',
                        displayType: 3,
                        sortable: false,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['DA', 'NE'],
                            filterFn: this.filterStatus
                        }
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true
                    const responseStockTakingScanners = await this.$http.get('/api/admin/v1/stock_taking_scanners')
                    this.stockTakingScanners = responseStockTakingScanners.data ?? []
                    const responseClients = await this.$http.get('/api/admin/v1/client')
                    this.clients = responseClients.data ?? []
                } catch {
                    this.$printError('Prišlo je do napak')
                } finally {
                    this.showLoader = false
                }

            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`api/admin/v1/stock_taking_scanners/${id}`)
                    this.$printSuccess('Brisanje je bilo uspešno!')
                    this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)

                }
            },
            getClientNameFromId(id) {
                const item = this.clients.find(ele => ele.id === id)

                if (item) {
                    return item.name
                }
                return '/'
            },
            filterClientNames(row, filterValue) {
                const clientName = this.getClientNameFromId(row)
                return clientName.toLowerCase().includes(filterValue.toLowerCase())
            },
            filterStatus(row, filterValue) {
                return row === (filterValue === 'DA')
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>