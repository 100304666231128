<template>
    <b-modal title="Skener" v-model="modalActive" size="lg" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Ime">
                    <validation-provider #default="{ errors }" name="Ime" rules="required">
                        <b-form-input v-model="object.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Stranka" v-if="!editing">
                    <validation-provider #default="{ errors }" name="Stranka" rules="required">
                        <v-select v-model="object.client_id" :options="clients" :reduce="item => item.id" appendToBody :clearable="false" >
                            <template #selected-option="{name}">
                                <div>
                                    {{name}}
                                </div>
                            </template>
                            <template v-slot:option="option">
                                {{option.name}}
                            </template>
                        </v-select>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Uredi</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">Dodaj</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import vSelect from 'vue-select'
    import '@core/scss/vue/libs/vue-select.scss'

    export default {
        props: {
            clients: {
                type: Array
            }
        },
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        computed: {
        },
        data() {
            return {
                modalActive: false,
                object: {
                    name: '',
                    client_id: ''
                },
                editing: false,
                required
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.object = JSON.parse(JSON.stringify(data))
                    this.editing = true
                } else {
                    this.object = {
                        name: '',
                        client_id: ''
                    }
                    this.editing = false
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.object.registered = false
                    this.object.added_timestamp = new Date().toISOString()
                    this.object.registration_code = Math.random().toString(36).substring(2, 8)
                    const response = await this.$http.post('/api/admin/v1/stock_taking_scanners', this.object)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        client_id: ''
                    }
                } catch (error) {
                    if (error && error.response && error.response.data && (error.response.data.error === 'user with this email exists')) {
                        this.$printError('Uporabnik s tem emailom že obstaja')
                    } else {
                        this.$printError('Prišlo je do napake')
                    }
                }
            },
            async edit() {
                try {
                    const payload = {
                        name: this.object.name
                    }
                    await this.$http.put(`/api/admin/v1/stock_taking_scanners/${this.object.id}`, payload)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess('Urejanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        client_id: ''
                    }
                } catch (error) {
                    if (error && error.response && error.response.data && (error.response.data.error === 'user with this email exists')) {
                        this.$printError('Uporabnik s tem emailom že obstaja')
                    } else {
                        this.$printError('Prišlo je do napake')
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
